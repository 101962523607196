import styled from '@emotion/styled';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '@/components/Button/Button';
import FormInput from '@/components/Forms/FormInput';

// TODO:
// - PW reset for admins / staff

const LoginCard = styled.div`
  width: 90%;
  max-width: 528px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 51px;
  gap: 25px;
  border-radius: 30px;
  background: var(--White, #fff);

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 33px;
    background: none;
  }

  @media (min-width: 768px) {
    /* Styles for larger screens */
    padding: 40px 51px;
  }
`;
const TitlePrompt = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 14px;

  h1 {
    color: #3e3e3e;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'GT Eesti Text';
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 33px */
    letter-spacing: -0.36px;
    font-size: 24px;

    @media (min-width: 768px) {
      font-size: 30px;
      letter-spacing: -0.45px;
    }
  }

  p {
    color: #5d7179;
    text-align: center;
    font-family: 'GT Eesti Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 23.2px */
  }
`;
const ErrorText = styled.div`
  color: red;
  font-size: 12px;
  font-weight: 400;
`;
const ForgotPassword = styled.button`
  align-self: flex-end;
  color: var(--Primary, #3a55de);
  font-size: 12px;
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
`;
const NoAccountPrompt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #777;
  text-align: center;
  font-family: 'GT Eesti Text';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  a {
    text-decoration: none;
    color: var(--Primary, #3a55de);
  }
`;
export default function AdminLogin() {
  const router = useRouter();
  const session = useSession();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loginError, setLoginError] = useState('');
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();
  const signInUser = async (data: {
    email: string;
    password: string;
  }) => {
    const {
      email,
      password
    } = data;
    setIsLoading(true);
    const signInResponse = await signIn('credentials', {
      email,
      password,
      redirect: false
    });
    if (signInResponse?.error?.length > 0) {
      setLoginError('Invalid email or password');
    } else {
      setLoginError('');
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (session?.data?.user?.role === 'provider' || session?.data?.user?.role === 'clinicalStaff') {
      router.push('/staff/appointments');
    }
    if (session?.data?.user?.role === 'globalAdmin') {
      router.push('/admin/list/appointments');
    }
  }, [session]);
  return <>
      <LoginCard data-sentry-element="LoginCard" data-sentry-source-file="AdminLogin.tsx">
        <div style={{
        width: '200px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
          <img src="/mh-logo.svg" alt="logo" style={{
          width: 'auto',
          height: '50px'
        }} />
        </div>

        <TitlePrompt data-sentry-element="TitlePrompt" data-sentry-source-file="AdminLogin.tsx">
          <h1>Login to your Dashboard</h1>
          <p>Welcome to Modern Halo. Please log in to your account</p>
        </TitlePrompt>

        <form onSubmit={handleSubmit(signInUser)}>
          <Controller name="email" control={control} rules={{
          required: 'Email is required',
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: 'Invalid email address'
          }
        }} render={({
          field
        }) => <FormInput name="email" id="email-login-input" label="Email Address" currentValue={field.value} updateFn={field.onChange} error={errors.email} autoComplete="email" />} data-sentry-element="Controller" data-sentry-source-file="AdminLogin.tsx" />

          <Controller name="password" control={control} rules={{
          required: 'Password is required',
          minLength: {
            value: 6,
            message: 'Password must be at least 6 characters long'
          }
        }} render={({
          field
        }) => <FormInput name="password" type="password" id="password-login-input" label="Password" currentValue={field.value} updateFn={field.onChange} error={errors.password} autoComplete="on" />} data-sentry-element="Controller" data-sentry-source-file="AdminLogin.tsx" />

          {loginError && <ErrorText>{loginError}</ErrorText>}

          <ForgotPassword type="button" onClick={() => router.push('/forgot-password')} data-sentry-element="ForgotPassword" data-sentry-source-file="AdminLogin.tsx">
            Forgot your password?
          </ForgotPassword>

          <Button variant="primary" style={{
          width: '100%'
        }} type="submit" isLoading={isLoading} data-sentry-element="Button" data-sentry-source-file="AdminLogin.tsx">
            Log in
          </Button>
        </form>

        <NoAccountPrompt data-sentry-element="NoAccountPrompt" data-sentry-source-file="AdminLogin.tsx">
          <p>Don't have an account?</p>
          <a href="mailto:support@modernhalo.com">Contact the admin to get an invite</a>
        </NoAccountPrompt>
      </LoginCard>
    </>;
}