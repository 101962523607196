import styled from '@emotion/styled';
import { ChangeEvent, FocusEvent, forwardRef, RefObject, useEffect, useState } from 'react';
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form';
const Container = styled.div`
  width: 100%;
`;
const ErrorText = styled.p`
  color: var(--Error, #f45555);
  font-family: 'GT Eesti Text';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 17.4px */
  margin-top: 6px;
  margin-left: 4px;
`;
const FormGroup = styled.div<{
  hasError?: boolean;
}>`
  position: relative;
  width: 100%;

  &.focused .form-label,
  &.filled .form-label {
    transform: translateY(-130%) translateX(-10%) scale(0.8);
    padding: 0px 4px;
  }

  &.disabled .form-label {
    transform: translateY(-170%) translateX(-10%) scale(0.8);
  }
`;
const FormLabel = styled.label`
  position: absolute;
  left: 10px;
  top: 55%;
  transform: translateY(-55%);
  background: none;
  z-index: 1;
  transition: all 150ms ease-out;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 28px);
  color: var(--Color, #99a7ae);
  font-family: 'GT Eesti Text';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
`;
const FormInput = styled.input`
  position: relative;
  padding: 15px 14px;
  width: 100%;
  outline: 0;
  border-radius: 10px;
  border: 1px solid rgba(0, 35, 52, 0.2);
  background: var(--White, #fff);
  box-shadow: 0 1px 0 0 #e5e5e5;
  transition: box-shadow 150ms ease-out, border-color 150ms ease-out;

  color: var(--Body---Brand, #3e3e3e);
  font-family: 'GT Eesti Text';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */

  &:-webkit-autofill {
    animation-name: autofill;
    animation-fill-mode: both;
  }

  &:-webkit-autofill:disabled {
    border: none;
    animation-name: none;
    animation-fill-mode: none;
  }

  @keyframes autofill {
    0%,
    100% {
      color: #666;
      background: var(--White, #fff);
    }
  }

  &.error {
    border-color: #f45555;
  }

  &:disabled {
    border-color: transparent;
    box-shadow: none;
  }
`;

// Add some styling for the units
const UnitsSpan = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
`;

// Type definitions for component props
// Note: these props are used in NumericalInput as well, so any changes here will affect that component as well
export interface InputWithAnimatedPlaceholderProps<T extends FieldValues> {
  id: string;
  label: string;
  currentValue: string;
  type?: string;
  step?: string;
  updateFn: (value: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  error?: FieldError | {
    message: string;
  };
  dirty?: boolean;
  register?: UseFormRegister<T>;
  name: string;
  maxLength?: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  units?: string;
  autoComplete?: string;
}

/**
 * InputWithAnimatedPlaceholder Component
 *
 * A custom input component with an animated placeholder.
 *
 * @param {string} id - The id of the input element.
 * @param {string} label - The label for the input element.
 * @param {string} currentValue - The current value of the input.
 * @param {(value: string) => void} updateFn - Function to update the input value.
 * @param {(event: FocusEvent<HTMLInputElement>) => void} [onBlur] - Optional blur event handler.
 * @param {FieldError | { message: string }} error - Optional error message(s)
 * @param {boolean} dirty - Indicates whether or not unsaved changes have been made to this field
 * @param {boolean} disabled - Indicates whether or not the input is disabled
 */
const InputWithAnimatedPlaceholder = forwardRef<HTMLInputElement, InputWithAnimatedPlaceholderProps<FieldValues>>(({
  id,
  label,
  currentValue,
  updateFn,
  onBlur,
  type = 'text',
  step,
  error,
  dirty = false,
  register,
  name,
  onKeyDown,
  maxLength,
  disabled = false,
  units = '',
  autoComplete = 'off'
}, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(currentValue !== '' && currentValue !== undefined);

  /**
   * Handles the focus event on the input.
   */
  const handleFocus = () => {
    setIsFocused(true);
  };

  /**
   * Handles the blur event on the input.
   *
   * @param {FocusEvent<HTMLInputElement>} event - The blur event.
   */
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    setIsFilled(event.target.value !== '' && event.target.value !== undefined);
    if (onBlur) onBlur(event);
  };

  /**
   * Handles the autofill animation event.
   *
   * @param {AnimationEvent} event - The animation event.
   */
  const handleAutoFillWrapper = (event: Event) => {
    if (event instanceof AnimationEvent && event.animationName === 'autofill') {
      setIsFilled(true);
    }
  };
  useEffect(() => {
    const input = document.getElementById(id) as HTMLInputElement;
    input.addEventListener('animationstart', handleAutoFillWrapper);
    return () => {
      input.removeEventListener('animationstart', handleAutoFillWrapper);
    };
  }, [id]);
  useEffect(() => {
    setIsFilled(currentValue !== '' && currentValue !== undefined);
  }, [currentValue]);
  useEffect(() => {
    // Initialize phone number format if type is tel and there's no value
    if (type === 'tel' && (!currentValue || currentValue === '')) {
      updateFn('(   )    -    ');
    }
  }, [type, currentValue, updateFn]);
  const hasError = error && !isFocused && error?.message?.length > 0;
  const formatPhoneNumber = (value: string) => {
    // Remove all non-numeric characters
    const numbers = value.replace(/\D/g, '');

    // Always maintain the format
    let formattedNumber;
    if (numbers.length === 0) {
      formattedNumber = '(   )    -    ';
    } else if (numbers.length <= 3) {
      // Fill the area code section, keeping spaces for unfilled positions
      const areaCode = numbers.padEnd(3, ' ');
      formattedNumber = `(${areaCode})    -    `;
    } else if (numbers.length <= 6) {
      // Fill the first two sections
      const prefix = numbers.slice(3, 6).padEnd(3, ' ');
      formattedNumber = `(${numbers.slice(0, 3)}) ${prefix}-    `;
    } else {
      // Fill all sections
      const lineNumber = numbers.slice(6, 10).padEnd(4, ' ');
      formattedNumber = `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${lineNumber}`;
    }
    return formattedNumber;
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === 'tel') {
      const cursorPosition = e.target.selectionStart || 0;
      const previousValue = currentValue || '';
      const newValue = formatPhoneNumber(e.target.value);
      updateFn(newValue);

      // Maintain cursor position logic
      requestAnimationFrame(() => {
        const input = e.target;
        if (input) {
          // Adjust cursor position based on the formatting
          let newPosition = cursorPosition;
          if (cursorPosition === 1 && newValue.length > previousValue.length) newPosition = 2;
          if (cursorPosition === 4) newPosition = 6;
          if (cursorPosition === 9) newPosition = 10;
          input.setSelectionRange(newPosition, newPosition);
        }
      });
    } else {
      updateFn(e.target.value);
    }
  };
  return <Container>
        <FormGroup className={`${isFocused ? 'focused' : ''} ${isFilled ? 'filled' : ''} ${disabled ? 'disabled' : ''}`} hasError={hasError}>
          <FormLabel className="form-label" htmlFor={id}>
            {label}
          </FormLabel>
          <FormInput id={id} disabled={disabled} className={hasError || dirty ? 'error' : 'form-input'} type={type} step={step} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} value={currentValue} ref={ref as RefObject<HTMLInputElement>} onKeyDown={onKeyDown} maxLength={maxLength} autoComplete={autoComplete} {...register && register(name)} />
          {units?.length > 0 && <UnitsSpan>{units}</UnitsSpan>}
        </FormGroup>
        {hasError && <ErrorText>{error.message}</ErrorText>}
      </Container>;
});
InputWithAnimatedPlaceholder.displayName = 'InputWithAnimatedPlaceholder';
export default InputWithAnimatedPlaceholder;